import React from "react"
import { Link } from "gatsby"
import { Box, Container, Grid, Heading, Themed } from "theme-ui"

const Boxes = ( props ) => {

  const color = props.color ? props.color : `#cfcfcf`
  const bg = props.bg ? props.bg : `black`
  const colorSquares = props.colorsquares ? props.colorsquares : `#3c3c3c`
  const title = props.title
  const content = props.content
  const columns = `repeat(${props.columns},1fr)`

  return (
    <Box
      as={`section`}
      sx={{
        background: `${bg}`,
        py: [5, 5, 5, 5, 6, 6],
        position: `relative`,
        overflow: `hidden`,
        maxWidth: `100vw`,
        "h2, h3, h4, li, p, a": {
          color: `${color}`,
        },
        h2: {
          fontSize: [0, 0, 0, 0, 1, 1],
          color: `tertiary`,
          mt: 0,
          mb: 4,
        },
        h3: {
          fontSize: [1, 1, 1, 1, 1, 2],
          "&:before": {
            backgroundColor: `${colorSquares}`,
            content: "''",
            display: `block`,
            width: 3,
            height: 3,
            my: 2,
          },
        },
        p: {
          fontSize: 1,
        },
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
      }}
    >
      <Container as={`div`}>
        {title &&
          <Heading as={`h2`}>{title}</Heading>
        }
        <Grid
          as={`div`}
          gap={[4, 5]}
          columns={[1, 3, 3, `${columns}`, `${columns}`]}
        >
          { content.map((item, index) => (
            <Box as={`div`} className="item" key={[index]}>
              <Heading as={`h3`}>{item.title}</Heading>
              <Themed.p>{item.content}</Themed.p>
              {item.link &&
                <Link to={`/${item.link}`} rel="bookmark">{item.textlink}</Link>
              }
            </Box>
          )) }
        </Grid>
      </Container>
    </Box>
  )

}

export default Boxes