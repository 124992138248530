import React from "react"
import { Link } from "gatsby"
import Square from "./square"
import { Box, Button, Container, Flex, Heading, Themed } from "theme-ui"
import SquaresMove from "../components/squares-move"

const Hero = ( props ) => {

  const color = props.color ? props.color : `#cfcfcf`
  const bg = props.bg ? props.bg : `black`
  const linkbuttoncta = props.linkbuttoncta
  const textintro = props.textintro
  const textbuttoncta = props.textbuttoncta
  const maintitle = props.title
  const squares = props.squares ? props.squares : false
  const squaresmove = props.squaresmove ? props.squaresmove : false

  return (
    <Box
      as={`section`}
      className="main-title"
      sx={{
        background: `${bg}`,
        backgroundSize: `cover`,
        pt: 7,
        pb: [5, 5, 5, 5, 6],
        position: `relative`,
        overflow: `hidden`,
        maxWidth: `100vw`,
        "h1, h2, h3, h4, span": {
          color: `${color}`,
        },
        button: {
          borderColor: `${color}`,
          p: 2,
          a: {
            color: `${color}`,
            textDecoration: `none`,
          }
        },
        h1: {
          fontFamily: `body`,
          fontSize: [4, 4, 4, 5, 6],
          fontWeight: `bold`,
          lineHeight: `none`,
          my: 3,
        },
        p: {
          fontSize: [1, 2],
          mt: 0,
        },
        ".squares svg+svg": {
          ml: 2,
        },
        ".content-header" : {
          display: [`inherit`, `inherit`, `inherit`, `flex`, `flex`],
        },
        ".content-header > div:nth-of-type(2)": {
          display: [`none`, `none`, `none`, `initial`, `initial`],
          flex: `0 1 33vw`,
        },
      }}
    >
      <Container as={`div`}>
        <Box
          as={`div`}
          className="content-header"
        >
          <Box as={`div`}>
            {squares &&
              <Flex className="squares">
                {
                  squares.map((item, index) =>(
                    <Square key={[index]} bgcolor={item} />
                  ))
                }
              </Flex>
            }
            <Heading as={`h1`}>
              {maintitle}
            </Heading>
            {textintro &&
              <Themed.p>{textintro}</Themed.p>
            }
            {(linkbuttoncta || textbuttoncta ) &&
              <Button><Link to={`/${linkbuttoncta}`}>{textbuttoncta}</Link></Button>
            }
          </Box>
          {squaresmove &&
            <SquaresMove />
          }
        </Box>
      </Container>
    </Box>
  )
}

export default Hero
