import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Accordion from "../components/accordion"
import Boxes from "../components/boxes"
import Seo from "../components/seo"
import bg24 from "../images/bg-24.png"
import bg28 from "../images/bg-28.png"
import bg14 from "../images/bg-14.png"
import theme from "../gatsby-plugin-theme-ui/index"

const Services = ({ data }) => {

  const hero = {
    'textintro': '',
    'title': 'Services offering',
    'squares': '',
    'squaresmove': true,
  }

  const box1 = {
    'title': 'Service Offering',
    'content': [
      {
        'title': 'Pilot',
        'content': 'Assess Readiness to become digital and API -driven. Design and sketch Digitalisations & Aplications Challenges. Estimate Challenge solutions Return on Investment (ROI) and pick the best ones to start with. Map your Architecture Actual and Desired Landscapes.',
      },
      {
        'title': 'Utilize',
        'content': 'Drill-down Challenge solutions and test. Advanced Data Migration. Resources Procurement: Open Innovation Partnerships.',
      },
      {
        'title': 'Expand',
        'content': 'Business Modelling and Financial Simulations. Define business tactics to reach new markets and customer segments. Resources Procurement: Corporate Venture Capital Advise.',
      }
    ]
  }

  const accordion = {
    'textfirst': true,
    'title': 'Impact services',
    'content': [
      {
        'title': 'Data Foundation Strategy',
        'content': 'Sharpen data for relevant decisions and take action. Understand current state of data quality and develop roadmaps. Conduct fact-finding and identify improvements. Implement a governance strategy',
        'link': 'data-foundation-strategy',
        'textlink': 'More',
      },
      {
        'title': 'Integration framework',
        'content': 'Work smarter with simple API’s. Real-time processing, security and performance. Identify sources of productivity and automate them with RPA technologies Share data with partners and clients to bring value to your business intelligence. Smoothly integrate new information systems and digital processes',
        'link': 'integration-framework',
        'textlink': 'More',
      },
      {
        'title': 'Cutting Edge industries: Retail',
        'content': 'Manage single customer view. Increase customer retention and satisfaction through personalization. Partner with your suppliers to ensure consistent growth. Improved customer engagement.',
        'link': 'retail',
        'textlink': 'More',
      },
      {
        'title': 'Cutting Edge industries: Logistics',
        'content': 'Improved customer service, reducing returns and simplification of processes with no added value. Improved delivery times over the last mile. Improved delivery efficiency on the “lot-size-of-one” challenge. Digitization of knowledge during the distribution process.',
        'link': 'logistics',
        'textlink': 'More',
      }
    ],
  }

  return (
    <Layout>
      <Seo
        title={data.site.siteMetadata.title}
        description="Pilot, Utilize, Expand, Data Foundation Strategy, Integration framework, Cutting Edge industries: Retail and Logistics"
      />
      <Hero
        bg={`${theme.colors.primary} url(${bg24}) no-repeat right bottom`}
        color='secondary'
        title={hero.title}
        textintro={hero.textintro}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Accordion
        bg={`#1e1e1e url(${bg28}) no-repeat center top`}
        textfirst={accordion.textfirst}
        title={accordion.title}
        content={accordion.content}
      />
      <Boxes
        colorsquares='secondary'
        bg={`#1e1e1e url(${bg14}) no-repeat center bottom`}
        columns={box1.content.length}
        title={box1.title}
        content={box1.content}
      />

    </Layout>
  )
  
}

export default Services

export const query = graphql`
  query Services {
    site {
      siteMetadata {
        title
      }
    }
  }
`
