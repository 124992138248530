import React from "react"
import { Box } from "theme-ui"
import Square from "./square"

const SquaresMove = () => {

  return (
    <Box
      as={`div`}
      sx={{
        '@keyframes thread': {
          '0%': {
            transform: 'translate(0px, 50vh)',
            opacity: 0.8,
          },
          '100%': {
            transform: 'translate(0px, -10vh)',
            opacity: 0.4,
          }
        },
        maxHeight: [12, 12, 12, 32, 24],
        overflow: `hidden`,
        svg: {
          m: 2,
        },
        "svg:nth-of-type(1)": {
          animation: `thread 10s infinite`,
        },
        "svg:nth-of-type(2)": {
          animation: `thread 8s infinite`,
        },
        "svg:nth-of-type(3)": {
          animation: `thread 12s infinite`,
        },
        "svg:nth-of-type(4)": {
          animation: `thread 16s infinite`,
        },
        "svg:nth-of-type(5)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(6)": {
          animation: `thread 15s infinite`,
        },
        "svg:nth-of-type(7)": {
          animation: `thread 13s infinite`,
        },
        "svg:nth-of-type(8)": {
          animation: `thread 20s infinite`,
        },
        "svg:nth-of-type(9)": {
          animation: `thread 14s infinite`,
        },
        "svg:nth-of-type(10)": {
          animation: `thread 21s infinite`,
        },
        "svg:nth-of-type(11)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(12)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(13)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(14)": {
          animation: `thread 22s infinite`,
        },
        "svg:nth-of-type(15)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(16)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(17)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(18)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(19)": {
          animation: `thread 4s infinite`,
        },
        "svg:nth-of-type(20)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(21)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(22)": {
          animation: `thread 10s infinite`,
        },
        "svg:nth-of-type(23)": {
          animation: `thread 8s infinite`,
        },
        "svg:nth-of-type(24)": {
          animation: `thread 12s infinite`,
        },
        "svg:nth-of-type(25)": {
          animation: `thread 16s infinite`,
        },
        "svg:nth-of-type(26)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(27)": {
          animation: `thread 15s infinite`,
        },
        "svg:nth-of-type(28)": {
          animation: `thread 13s infinite`,
        },
        "svg:nth-of-type(29)": {
          animation: `thread 20s infinite`,
        },
        "svg:nth-of-type(30)": {
          animation: `thread 14s infinite`,
        },
        "svg:nth-of-type(31)": {
          animation: `thread 21s infinite`,
        },
        "svg:nth-of-type(32)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(33)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(34)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(35)": {
          animation: `thread 22s infinite`,
        },
        "svg:nth-of-type(36)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(37)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(38)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(39)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(40)": {
          animation: `thread 4s infinite`,
        },
        "svg:nth-of-type(41)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(42)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(43)": {
          animation: `thread 10s infinite`,
        },
        "svg:nth-of-type(44)": {
          animation: `thread 8s infinite`,
        },
        "svg:nth-of-type(45)": {
          animation: `thread 12s infinite`,
        },
        "svg:nth-of-type(46)": {
          animation: `thread 16s infinite`,
        },
        "svg:nth-of-type(47)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(48)": {
          animation: `thread 15s infinite`,
        },
        "svg:nth-of-type(49)": {
          animation: `thread 13s infinite`,
        },
        "svg:nth-of-type(50)": {
          animation: `thread 20s infinite`,
        },
        "svg:nth-of-type(51)": {
          animation: `thread 14s infinite`,
        },
        "svg:nth-of-type(52)": {
          animation: `thread 21s infinite`,
        },
        "svg:nth-of-type(53)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(54)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(55)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(56)": {
          animation: `thread 22s infinite`,
        },
        "svg:nth-of-type(57)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(58)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(59)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(60)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(61)": {
          animation: `thread 4s infinite`,
        },
        "svg:nth-of-type(62)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(63)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(64)": {
          animation: `thread 10s infinite`,
        },
        "svg:nth-of-type(65)": {
          animation: `thread 8s infinite`,
        },
        "svg:nth-of-type(66)": {
          animation: `thread 12s infinite`,
        },
        "svg:nth-of-type(67)": {
          animation: `thread 16s infinite`,
        },
        "svg:nth-of-type(68)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(69)": {
          animation: `thread 15s infinite`,
        },
        "svg:nth-of-type(70)": {
          animation: `thread 13s infinite`,
        },
        "svg:nth-of-type(71)": {
          animation: `thread 20s infinite`,
        },
        "svg:nth-of-type(72)": {
          animation: `thread 14s infinite`,
        },
        "svg:nth-of-type(73)": {
          animation: `thread 21s infinite`,
        },
        "svg:nth-of-type(74)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(75)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(76)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(77)": {
          animation: `thread 22s infinite`,
        },
        "svg:nth-of-type(78)": {
          animation: `thread 3s infinite`,
        },
        "svg:nth-of-type(79)": {
          animation: `thread 9s infinite`,
        },
        "svg:nth-of-type(80)": {
          animation: `thread 6s infinite`,
        },
        "svg:nth-of-type(81)": {
          animation: `thread 5s infinite`,
        },
        "svg:nth-of-type(82)": {
          animation: `thread 4s infinite`,
        },
        "svg:nth-of-type(83)": {
          animation: `thread 7s infinite`,
        },
        "svg:nth-of-type(84)": {
          animation: `thread 9s infinite`,
        },
      }}
    >
      <Square />
      <Square />
      <Square bgcolor='primary' />
      <Square />
      <Square />
      <Square bgcolor='secondary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='primary' />
      <Square />
      <Square bgcolor='secondary'/>
      <Square />
      <Square />
      <Square />
      <Square bgcolor='tertiary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='tertiary' />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='primary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='secondary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='tertiary' />
      <Square />
      <Square />
      <Square bgcolor='primary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='secondary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='tertiary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='primary' />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='secondary'/>
      <Square />
      <Square bgcolor='primary' />
      <Square />
      <Square />
      <Square />
      <Square bgcolor='tertiary' />
    </Box>
  )

}

export default SquaresMove