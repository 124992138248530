import React from "react"
import { Box } from "theme-ui"

const Square = ( props ) => {

  const bgColor = props.bgcolor ? props.bgcolor : `black`
  const size = props.size ? props.size : `10px`

  return(
    <Box
      as={`svg`}
      xmlns="http://www.w3.org/2000/svg"
      sx={{
      	backgroundColor: `${bgColor}`,
        width: `${size}`,
        height: `${size}`,
      }}
    >
      <rect />
    </Box>
  )

}

export default Square