import React, { useState } from "react"
import Footer from "./footer"
import Header from "./header"
import { Box, Themed } from "theme-ui"

const Layout = ( props ) => {

  const [isShown, setIsShown] = useState(true)

  return (
    <Themed.root>
      <Header
        stateOnMainComponent={setIsShown}
        linksnavcolor={props.linksnavcolor}
      />
      {isShown === true
      ? <Box
          as={`div`}
          className="show"
        >
          <main>{props.children}</main>
          <Footer />
        </Box>
      : <Box
          as={`div`}
          className="hide"
          sx={{
            height: `100vh`,
            overflow: `hidden`,
          }}
        >
          <main>{props.children}</main>
          <Footer />
        </Box>
      }
    </Themed.root>
  )

}

export default Layout
